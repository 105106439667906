<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="100px" size="mini">
            <el-form-item label="名称" prop="name"
                          :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item label="编码" prop="code"
                          :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.code"></el-input>
            </el-form-item>
            <el-form-item label="级别" prop="level" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-select v-model="form.level" placeholder="请选择" style="width:100%" clearable>
                    <el-option :label="item.name" :value="item.id" v-for="(item,index) in levelList" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="角色类型" prop="level" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
                <el-select v-model="form.type" placeholder="请选择" style="width:100%" clearable>
                    <el-option label="医生端" value="1"></el-option>
                    <el-option label="企业端" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="address">
                <el-input v-model.trim="form.desc" :rows="4" type="textarea"></el-input>
            </el-form-item>
            <el-form-item style="padding-top: 60px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                levelList:[
                    {name:"一级管理员",id:1},
                    {name:"二级管理员",id:2},
                    {name:"三级管理员",id:3},
                    {name:"四级管理员",id:4},
                    {name:"五级管理员",id:5},
                ],
            }
        },
        methods: {
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/company/role`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
