<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleAdd" v-has="'role_add'">添加
                </el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="角色管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="100px">
                <el-form-item label="级别">
                    <el-select v-model="search.level" placeholder="请选择" style="width:100%" clearable>
                        <el-option :label="item.name" :value="item.id" v-for="(item,index) in levelList" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色名称" >
                    <el-input placeholder="请输入内容" v-model="search.name"  clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'role_check'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%" height="100%">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column align="center" label="角色名称" prop="name">
                    </el-table-column>
                    <el-table-column align="center" label="角色编码" prop="code">
                    </el-table-column>
                    <el-table-column align="center" label="角色类型" prop="type">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.type=='1'">医生端</el-tag>
                            <el-tag v-else-if="scope.row.type=='2'" type="success">企业端</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="级别" prop="level">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.level==1">一级管理员</el-tag>
                            <el-tag v-else-if="scope.row.level==2">二级管理员</el-tag>
                            <el-tag v-else-if="scope.row.level==3">三级管理员</el-tag>
                            <el-tag v-else-if="scope.row.level==4">四级管理员</el-tag>
                            <el-tag v-else-if="scope.row.level==5">四级管理员</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="描述" prop="desc">
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" >
                        <template slot-scope="scope">
                            <div>{{ scope.row.created_at | datetime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'role_edit'">修改
                            </el-button>
                            <el-button type="text" size="mini" @click="handleDelete(scope.row)" v-has="'role_delete'">删除
                            </el-button>
                            <el-button type="text" size="mini" @click="handleQuanx(scope.row)" v-has="'update_quanx'">设置权限</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false"
                   @update="getData" ref="quanxian"></data-form>
        <quanxian-form :visible="qxVisible" :form="form" @close="qxVisible = false" :data="menuData"
                   @update="getData"></quanxian-form>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../../data/formatter"
    import dataForm from "./widgets/dataform";
    import quanxianForm from "./widgets/quanxianForm";

    export default {
        name: 'account',
        components: {
            dataForm,
            quanxianForm
        },
        data() {
            return {
                search: {},
                form: {},
                qxVisible: false,
                dialogVisible: false,
                formatter: formatter,
                loading: false,
                table: data.table,
                levelList:[
                    {name:"一级管理员",id:1},
                    {name:"二级管理员",id:2},
                    {name:"三级管理员",id:3},
                    {name:"四级管理员",id:4},
                    {name:"五级管理员",id:5},
                ],
                menuData:[]
            }
        },
        methods: {
            handleQuanx(row){
                this.form = row
                // if(row.type=='2'){
                this.menuData = data.companyMenu
                // }else {
                //     this.menuData = data.doctorMenu
                // }
                this.qxVisible = true
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/role/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handleAdd() {
                this.form = {}
                this.dialogVisible = true
            },
            handleRowChange(row){
                this.form = row
                this.dialogVisible = true
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.qxVisible = false
                this.dialogVisible = false
                this.loading = true
                const resp = await this.$http.get(`/company/role?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
