<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title"  @open="handleDialogOpen">
        <el-form ref="form" :model="form" label-width="100px" size="mini" >
            <el-form-item label="角色名称" prop="name"
                          :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name" readonly></el-input>
            </el-form-item>
            <el-form-item label="权限" prop="code"
                          :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-tree
                        style="height: 500px;overflow-y: auto;"
                        :data="data"
                        show-checkbox
                        default-expand-all
                        node-key="id"
                        ref="tree"
                        highlight-current
                        :props="defaultProps">
                </el-tree>
            </el-form-item>
            <el-form-item style="padding-top: 60px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
            title: {
                default: "添加/修改"
            },
            data:{
                default:[]
            }
        },
        data() {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'label'
                }
            }
        },
        methods: {
            async handleDialogOpen() {
                let menuList = []
                console.log(this.form.menuIds)
                if (this.form.menuIds) {
                    menuList = this.form.menuIds
                    this.data.forEach(e => {
                        // 删掉顶级目录，要不这个tree会都选上
                        if (e.children) {
                            menuList.remove(e.id)
                            e.children.forEach(ee => {
                                if (ee.children && ee.children.length) {
                                    menuList.remove(ee.id)
                                }
                            })
                        }
                    })
                }
                if (this.$refs['tree']) {
                    this.$refs['tree'].setCheckedKeys(menuList);
                } else {
                    setTimeout(() => {
                        this.$refs['tree'].setCheckedKeys(menuList);
                    }, 200)
                }
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                let menuIds = []
                let pckList = []
                var dataList = this.$refs.tree.getCheckedNodes();
                let currentNode = this.$refs.tree.getHalfCheckedNodes()
                dataList.forEach(e => {
                    menuIds.push(e.id)
                    if(e.permission){
                        pckList.push(e.permission)
                    }
                })
                currentNode.forEach(e => {
                    menuIds.push(e.id)
                })
                this.form.menuIds = menuIds
                this.form.permission = pckList
                try {
                    let url = `/company/role/permission`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
