const formatter = {}

formatter.formatDateTime = (row,column) => {
    let value = row[column.property]
    if (!value) return ''
    let date = new Date(value)
    return date.format('yyyy-MM-dd hh:mm:ss')
};

formatter.formatDateDay = (row,column) => {
    let value = row[column.property]
    if (!value) return ''
    let date = new Date(value)
    return date.format('yyyy-MM-dd')
};

formatter.formatkS = (row,column) => {
    let value = row[column.property];
    let ks = [];
    var str = "";
    if(value.length){
        value.forEach(function (item) {
            if(ks.indexOf(item.KSMC)<0){
                ks.push(item.KSMC)
            }
        })
    }
    if(ks.length){
        str = ks[0] + "..."
    }
    return str
};

formatter.formatTJXM = (row,column) => {
    let value = row[column.property];
    let ks = [];
    if(value.length){
        value.forEach(function (item) {
            if(ks.indexOf(item.name)<0){
                ks.push(item.name)
            }
        })
    }
    return ks.join("、")
};

formatter.formatSFZT = (row,column) => {
    let value = row[column.property];
    switch (value) {
        case 0:
            return "未收费"
        case 1:
            return "已收费"
        case 2:
            return "待退费"
        case 3:
            return "已退费"
        default:
            return ""
    }
};

formatter.formatTJLX = (row,column) => {
    let value = row[column.property];
    switch (value) {
        case "P":
            return "个人"
        case "T":
            return "团体"
        default:
            return ""
    }
};

formatter.stateFormatter = (s) => {
    switch (s.state) {
        case 5:
            return "已忽略"
        case 4:
            return "已叫号"
        case 3:
            return "已沟通"
        case 2:
            return "已完成"
        case 1:
            return "已收费"
        case 0:
            return "已预约"
        case -1:
            return "用户取消"
        default:
            return s.state
    }
}

formatter.dateFormatter = (row) => {
        const temp = row.date +" "+ row.time
        return temp
}

formatter.statusFormatter = (row,column) => {
    const status = row[column.property]
    switch (status) {
        case 5:
            return "已忽略"
        case 4:
            return "已叫号"
        case 3:
            return "已沟通"
        case 2:
            return "已完成"
        case 1:
            return "已到院"
        case 0:
            return "已预约"
        case -1:
            return "已取消"
        default:
            return status
    }
}

formatter.planTimeUnitFormatter = (planTimeUnit) => {
    switch (planTimeUnit) {
        case "d":
            return "天"
        default:
            return status
    }
}

formatter.formatFstatus = (row,column) => {
    let value = row[column.property];
    switch (value) {
        case 1:
            return "待执行"
        case 2:
            return "进行中"
        case 3:
            return "已完成"
        default:
            return ""
    }
};

export default formatter
